<template>
  <b-modal v-model="shown" title="Add Member" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit" @reset="onHide">
      <b-form-group label="User:">
        <el-select :placeholder="$t('divisions.placeholder_select_add_member')" filterable :filter-method="handlerSearch" v-model="form.users_id" multiple size="small" class="w-100">
          <el-option v-for="item in users_list" :key="item.id" :value="item.id" :label="item.name" :disabled="item.disabled"></el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.users_id.$invalid" v-if="v$.form.users_id.$errors[0]">
          {{ $t('validation.' + v$.form.users_id.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Set as Division Admin:">
        <el-switch
          v-model="form.is_admin"
          active-color="#13ce66"
          inactive-color="#ff4949" />
      </b-form-group>
      <div class="d-flex flex-row-reverse">
        <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
        <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required, minLength,
} from '@vuelidate/validators';
import divisionsApi from '../../../api/divisions';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'DivisionAddMember',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        users_id: { required, minLength: minLength(1) },
      },
    };
  },
  data() {
    return {
      shown: false,
      form: {
        users_id: [],
        is_admin: false,
      },
      users_list: [],
      search_user: {
        search: '',
        member_group_id: this.$route.params.id,
      },
    };
  },
  methods: {
    onHide() {
      this.shown = false;
      this.$emit('onHide');
    },
    handlerSearch(value) {
      this.search_user.search = value;
      this.getUserList();
    },
    async onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      this.$parent.showLoader();
      const data = {
        users_id: this.form.users_id,
        groups_id: [this.$route.params.id],
        is_admin: this.form.is_admin,
      };
      const response = await divisionsApi.memberAdd(data);
      this.$parent.hideLoader();

      popupErrorMessages(response);
      this.$message({
        message: this.$t('divisions.success_create'),
        type: 'success',
      });
      this.onHide();
      this.form = {
        users_id: [],
        is_admin: false,
      };
      this.users_list = [];
      this.$parent.getList();
    },
    async getUserList() {
      await divisionsApi.nonMemberList(this.search_user).then((res) => {
        this.users_list = res.data.rows;
      });
    },
  },
  watch: {
    show() {
      this.shown = this.show;
      if (this.shown) {
        this.v$.form.$touch();
        this.getUserList();
      }
    },
  },
};
</script>
